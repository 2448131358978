import checks from "assets/images/backgrounds/checks.png";
import backArrowWhite from "assets/images/icons/back-arrow-white.png";
import map from "assets/images/pages/personality-test/map.png";
import result from "assets/images/pages/personality-test/result.png";
import test from "assets/images/pages/personality-test/test.png";
import { questions } from "config/16-personalities/questions";
import { calculateMBTI } from "config/16-personalities/result";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PersonalityTest = () => {
  useLayoutConfig("red", true);
  const navigate = useNavigate();
  const [answers, setAnswers] = useState(new Array(60).fill(undefined));
  const { profile, setProfile } = useContext(AuthContext);

  useDocumentTitle("Muskurahat Foundation | Donations");

  const handleBackClick = () => {
    navigate(-1);
  };

  function scrollToId(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "50px 50px 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    paddingBottom: 10,
    marginTop: 30,
    boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "40px 0",
    minHeight: "50vh",
  };

  const [page, setPage] = useState(1);

  const paginateArray = (pageNumber) => {
    // Calculate the starting and ending index for the slice
    const itemsPerPage = 6;
    const arr = questions;
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Use slice to get the items for the current page
    return arr.slice(startIndex, endIndex);
  };

  useEffect(() => {
    profile.personality && navigate("/user/personality-result");
  }, [profile.personality]);

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative mb-14">
        <img
          src={backArrowWhite}
          alt="back"
          onClick={handleBackClick}
          className="absolute left-5 top-2 w-5"
        />
        <h1 className="font-bold text-white text-xl text-center flex-grow">
          PERSONALITY TEST✨
        </h1>
      </div>

      <div style={overlayStyle}>
        <div className="px-[20px]">
          <div className="flex w-full gap-5 bg-white rounded-[13px] shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)]">
            <div className="bg-primary w-1/2 rounded-l-[14px] flex justify-center items-center">
              <img src={map} alt="map" className="w-[85px] h-auto" />
            </div>
            <div className="w-1/2 rounded-r-[14px] p-3">
              <h2 className="text-primary font-bold text-[16px]">
                UNLOCK YOUR
                <br /> POTENTIAL
              </h2>
              <p className="text-[#414141] font-normal text-[12px]">
                Gain Clarity On Yourself, Your Strengths, Weaknesses, And
                Explore Your Ideal Career Paths With This Personality Test!
              </p>
            </div>
          </div>

          <div className="flex w-full mt-10 gap-3 bg-white rounded-[13px] shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)]">
            <div className="w-1/2 rounded-l-[14px] p-5">
              <h2 className="text-tertiary font-bold text-[16px]">
                COMPLETE THE TEST
              </h2>
              <p className="text-[#414141] font-normal text-[12px]">
                Be Yourself And Answer Honestly To Find Out Your Personality
                Type.
              </p>
            </div>
            <div className="bg-tertiary w-1/2 rounded-r-[14px] flex justify-center items-center">
              <img src={test} alt="map" className="w-[85px] h-auto" />
            </div>
          </div>

          <div className="flex w-full mt-10 gap-5 bg-white rounded-[13px] shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)]">
            <div className="bg-secondary w-1/2 rounded-l-[14px] flex justify-center items-center">
              <img src={result} alt="map" className="w-[85px] h-auto" />
            </div>
            <div className="w-1/2 rounded-r-[14px] p-3">
              <h2 className="text-secondary font-bold text-[16px]">
                VIEW DETAILED
                <br /> RESULTS
              </h2>
              <p className="text-[#414141] font-normal text-[12px]">
                Learn How Your Personality Type Influences Many Areas Of Your
                Life.
              </p>
            </div>
          </div>
        </div>
        {/* progress */}

        <div className="py-10 px-[20px] mt-5 bg-white">
          <div>{(page * 100) / 10}%</div>
          <div className="w-full h-[10px] bg-[#E5E5E5] rounded-[10px]">
            <div
              style={{ width: `${(page * 100) / 10}%` }}
              className="h-full bg-tertiary rounded-[10px]"
            ></div>
          </div>
        </div>

        {/* questions 1st page */}
        {paginateArray(page).map((question, i) => (
          <div
            id={`question-${i}`}
            key={i}
            className={`py-5 ${
              i % 2 === 0 ? "bg-[#F4F4F4]" : "bg-white"
            } px-[20px]`}
          >
            <h3 className="text-black text-[16px] text-center">{question}</h3>
            <div className="flex mt-3 gap-2 justify-between items-center">
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = -3;
                  setAnswers(newAnswers);
                }}
                className={`h-[50px] w-[50px] rounded-full border-secondary ${
                  answers[(page - 1) * 6 + i] === -3 ? "bg-secondary" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = -2;
                  setAnswers(newAnswers);
                }}
                className={`h-[40px] w-[40px] rounded-full border-secondary ${
                  answers[(page - 1) * 6 + i] === -2 ? "bg-secondary" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = -1;
                  setAnswers(newAnswers);
                }}
                className={`h-[30px] w-[30px] rounded-full border-secondary ${
                  answers[(page - 1) * 6 + i] === -1 ? "bg-secondary" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = 0;
                  setAnswers(newAnswers);
                }}
                className={`h-[15px] w-[15px] rounded-full border-[#D9D9D9] ${
                  answers[(page - 1) * 6 + i] === 0 ? "bg-[#D9D9D9]" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = 1;
                  setAnswers(newAnswers);
                }}
                className={`h-[30px] w-[30px] rounded-full border-primary ${
                  answers[(page - 1) * 6 + i] === 1 ? "bg-primary" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = 2;
                  setAnswers(newAnswers);
                }}
                className={`h-[40px] w-[40px] rounded-full border-primary ${
                  answers[(page - 1) * 6 + i] === 2 ? "bg-primary" : ""
                } border-solid border-[2px]`}
              ></button>
              <button
                onClick={() => {
                  const newAnswers = [...answers];
                  newAnswers[(page - 1) * 6 + i] = 3;
                  setAnswers(newAnswers);
                }}
                className={`h-[50px] w-[50px] rounded-full border-primary ${
                  answers[(page - 1) * 6 + i] === 3 ? "bg-primary" : ""
                } border-solid border-[2px]`}
              ></button>
            </div>
            <div className="flex justify-between">
              <div className="text-secondary">Agree</div>
              <div className="text-primary">Disagree</div>
            </div>
          </div>
        ))}

        <button
          onClick={async () => {
            // check if all questions from current page are answered
            const result = paginateArray(page).every((question, i) => {
              console.log(answers[(page - 1) * 6 + i]);

              // if not, goto earliest unanswered question
              if (answers[(page - 1) * 6 + i] === undefined) {
                // goto that question
                scrollToId(`question-${i % 6}`);
                // break the loop
                return false;
              }
              return true;
            });

            if (!result) return;

            // if last page, submit the answers
            if (page === 10) {
              // submit the answers
              // create JSON for req
              const req = {
                answers,
                gender: "Male",
              };

              const res = calculateMBTI(req);
              // write to db
              setProfile({ ...profile, personality: res });

              return;
            }
            // else go to next page
            setPage(page + 1);
          }}
          className="w-fit px-3 py-2 rounded-xl m-auto bg-secondary text-white flex justify-center items-center gap-2"
        >
          {page === 10 ? (
            <span className="text-[16px]">Submit</span>
          ) : (
            <>
              <span className="text-[16px]">Next</span>
              <span>
                <svg
                  viewBox="0 0 48 48"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                  fill="white"
                  height={20}
                  width={20}
                >
                  <path
                    d="M23.296875,3.421875 L22.63125,4.0875 C22.190625,4.528125 22.190625,5.240625 22.63125,5.68125 L39.365625,22.40625 L4.125,22.40625 C3.50625,22.40625 3,22.9125 3,23.53125 L3,24.46875 C3,25.0875 3.50625,25.59375 4.125,25.59375 L39.365625,25.59375 L22.63125,42.328125 C22.190625,42.76875 22.190625,43.48125 22.63125,43.921875 L23.296875,44.5875 C23.7375,45.028125 24.45,45.028125 24.890625,44.5875 L44.68125,24.796875 C45.121875,24.35625 45.121875,23.64375 44.68125,23.203125 L24.890625,3.421875 C24.440625,2.98125 23.7375,2.98125 23.296875,3.421875 Z"
                    aria-hidden="true"
                  ></path>
                </svg>
              </span>
            </>
          )}
        </button>

        <button
          className="bg-primary text-white px-3 py-2 rounded-xl m-auto"
          onClick={() => {
            // randomly answer all questions except last
            const newAnswers = [...answers];
            for (let i = 0; i < 59; i++) {
              // -3 to 3
              newAnswers[i] = Math.floor(Math.random() * 7) - 3;
            }
            setAnswers(newAnswers);
            setPage(10);
          }}
        >
          Master Submit
        </button>
      </div>
    </>
  );
};

export default PersonalityTest;
