export const calculateMBTI = ({ answers, gender }) => {
  console.log(gender);
  const dimensions = {
    E: 0,
    I: 0, // Energy
    N: 0,
    S: 0, // Mind
    T: 0,
    F: 0, // Nature
    J: 0,
    P: 0, // Tactics
  };

  // Evaluate answers (answers should be an array of 60 values ranging from -3 to 3)
  for (let i = 0; i < answers.length; i++) {
    const score = answers[i];

    if (i % 4 === 0) {
      dimensions[score > 0 ? "E" : "I"] += Math.abs(score);
    } else if (i % 4 === 1) {
      dimensions[score > 0 ? "N" : "S"] += Math.abs(score);
    } else if (i % 4 === 2) {
      dimensions[score > 0 ? "T" : "F"] += Math.abs(score);
    } else {
      dimensions[score > 0 ? "J" : "P"] += Math.abs(score);
    }
  }

  // Determine the dominant trait for each dimension
  const personalityCode = `${dimensions.E > dimensions.I ? "E" : "I"}${
    dimensions.N > dimensions.S ? "N" : "S"
  }${dimensions.T > dimensions.F ? "T" : "F"}${
    dimensions.J > dimensions.P ? "J" : "P"
  }`;

  // Define detailed MBTI result mappings for all 16 personality types
  const mbtiResults = {
    ISTJ: {
      niceName: "Logistician",
      fullCode: "ISTJ-A",
      avatarSrc: "",
      avatarAlt: "ISTJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Logisticians are practical and fact-minded individuals, whose reliability cannot be doubted.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    ISFJ: {
      niceName: "Defender",
      fullCode: "ISFJ-A",
      avatarSrc: "",
      avatarAlt: "ISFJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Defenders are very dedicated and warm protectors, always ready to defend their loved ones.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    INFJ: {
      niceName: "Advocate",
      fullCode: "INFJ-A",
      avatarSrc: "",
      avatarAlt: "INFJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Advocates are quiet and mystical, yet very inspiring and tireless idealists.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    INTJ: {
      niceName: "Architect",
      fullCode: "INTJ-A",
      avatarSrc: "",
      avatarAlt: "INTJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Architects are imaginative and strategic thinkers, with a plan for everything.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },

        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },

        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    ISTP: {
      niceName: "Virtuoso",
      fullCode: "ISTP-A",
      avatarSrc: "",
      avatarAlt: "ISTP avatar",
      avatarSrcStatic: "",
      snippet:
        "Virtuosos are innovative and practical experimenters, masters of all kinds of tools.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ISFP: {
      niceName: "Adventurer",
      fullCode: "ISFP-A",
      avatarSrc: "",
      avatarAlt: "ISFP avatar",
      avatarSrcStatic: "",
      snippet:
        "Adventurers are flexible and charming artists, always ready to explore and experience something new.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    INFP: {
      niceName: "Mediator",
      fullCode: "INFP-A",
      avatarSrc: "",
      avatarAlt: "INFP avatar",
      avatarSrcStatic: "",
      snippet:
        "Mediators are poetic, kind, and altruistic people, always eager to help a good cause.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    INTP: {
      niceName: "Logician",
      fullCode: "INTP-A",
      avatarSrc: "",
      avatarAlt: "INTP avatar",
      avatarSrcStatic: "",
      snippet:
        "Logicians are innovative inventors with an unquenchable thirst for knowledge.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "introversion",
          label: "Energy",
          color: "blue",
          score: 18,
          pct: 45,
          trait: "Introversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
          snippet:
            "You’re reflective and prefer activities that allow for introspection.",
          imageAlt: "A person sitting quietly and reading a book.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ESTP: {
      niceName: "Entrepreneur",
      fullCode: "ESTP-A",
      avatarSrc: "",
      avatarAlt: "ESTP avatar",
      avatarSrcStatic: "",
      snippet:
        "Entrepreneurs are smart, energetic, and very perceptive people, who truly enjoy living on the edge.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ESFP: {
      niceName: "Entertainer",
      fullCode: "ESFP-A",
      avatarSrc: "",
      avatarAlt: "ESFP avatar",
      avatarSrcStatic: "",
      snippet:
        "Entertainers are spontaneous, energetic, and enthusiastic performers – life is never boring around them.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ENFP: {
      niceName: "Campaigner",
      fullCode: "ENFP-A",
      avatarSrc: "",
      avatarAlt: "ENFP avatar",
      avatarSrcStatic: "",
      snippet:
        "Campaigners are enthusiastic, creative, and sociable free spirits, who can always find a reason to smile.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ENTP: {
      niceName: "Debater",
      fullCode: "ENTP-A",
      avatarSrc: "",
      avatarAlt: "ENTP avatar",
      avatarSrcStatic: "",
      snippet:
        "Debaters are smart and curious thinkers who cannot resist an intellectual challenge.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "prospecting",
          label: "Tactics",
          color: "purple",
          score: 22,
          pct: 61,
          trait: "Prospecting",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
          snippet:
            "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
          imageAlt:
            "A scene showing a couple buying a bunch of items that are on sale.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
        },
      ],
    },
    ESTJ: {
      niceName: "Executive",
      fullCode: "ESTJ-A",
      avatarSrc: "",
      avatarAlt: "ESTJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Executives are excellent administrators, unsurpassed at managing things – or people.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    ESFJ: {
      niceName: "Consul",
      fullCode: "ESFJ-A",
      avatarSrc: "",
      avatarAlt: "ESFJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Consuls are extraordinarily caring, social, and popular people, always eager to help.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "sensing",
          label: "Information",
          color: "green",
          score: 25,
          pct: 62,
          trait: "Sensing",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
          snippet: "You’re detail-oriented and focus on tangible facts.",
          imageAlt: "A person carefully examining a map.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    ENFJ: {
      niceName: "Protagonist",
      fullCode: "ENFJ-A",
      avatarSrc: "",
      avatarAlt: "ENFJ avatar",
      avatarSrcStatic: "",
      snippet:
        "Protagonists are charismatic and inspiring leaders, able to mesmerize their listeners.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "feeling",
          label: "Decision-Making",
          color: "pink",
          score: 18,
          pct: 45,
          trait: "Feeling",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
          snippet: "You prioritize empathy and emotional connections.",
          imageAlt: "A person showing care and compassion towards others.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
    ENTJ: {
      niceName: "Commander",
      fullCode: "ENTJ-A",
      avatarSrc: "",
      avatarAlt: "ENTJ avatar",
      avatarSrcStatic: "",
      mainImage: "/images/personalities/commander/main.svg",
      snippet:
        "Commanders are bold, imaginative, and strong-willed leaders, always finding a way – or making one.",
      scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
      traits: [
        {
          key: "extraversion",
          label: "Energy",
          color: "orange",
          score: 22,
          pct: 55,
          trait: "Extraversion",
          link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
          titles: [null],
          reverse: false,
          description:
            "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
          snippet:
            "You’re outgoing and gain energy from interacting with others.",
          imageAlt: "A person enjoying a lively social event.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
        },
        {
          key: "intuition",
          label: "Information",
          color: "yellow",
          score: 20,
          pct: 50,
          trait: "Intuition",
          link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
          titles: [null],
          reverse: false,
          description:
            "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
          snippet: "You’re imaginative and focus on possibilities and ideas.",
          imageAlt: "A person envisioning creative ideas.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
        },
        {
          key: "thinking",
          label: "Decision-Making",
          color: "red",
          score: 24,
          pct: 60,
          trait: "Thinking",
          link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
          titles: [null],
          reverse: false,
          description:
            "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
          snippet: "You prefer logic and objectivity in decision-making.",
          imageAlt: "A person analyzing data on a screen.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
        },
        {
          key: "judging",
          label: "Tactics",
          color: "purple",
          score: 28,
          pct: 70,
          trait: "Judging",
          link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
          titles: [null],
          reverse: false,
          description:
            "Judging individuals prefer structure, organization, and sticking to plans.",
          snippet: "You prefer a structured and organized approach.",
          imageAlt: "A person planning their day on a calendar.",
          imageSrc:
            "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
        },
      ],
    },
  };

  return mbtiResults[personalityCode];
};
